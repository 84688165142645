<template>
  <div id="about">
    <div id="about_header">
      <h3>About Us</h3>
    </div>
    <div id="about_content">
      <p>
        <strong>Your partner for impeccable electrical work</strong>
      </p>
      <p>
        Dave Schwartzentruber has lived in the New Hamburg and Stratford areas
        for upwards of 50 years. He began his career with a high-school co-op
        program and graduated from Conestoga College with a Master Electrician
        diploma in 1992. He has recently recruited an apprentice, his daughter
        Emma Schwartzentruber.
      </p>
      <p>
        IHS Electric is committed to delivering clean, well structured
        electrical work, and will rise to the challenge of meeting your needs.
        Whether you’re looking to update your home, building a new home, or want
        to upgrade your electrical fixtures to be more energy-efficient, Dave
        and Emma can help!
      </p>
      <p>Call or email today for a quote!</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "About",
};
</script>

<style>
#about_header,
#about_content {
  text-align: left;
}

#about_content {
  font-size: 70%;
}
</style>
